import {useCallback, useEffect, useState, useMemo} from 'react'

import {
  Grid, Tabs, Tab, makeStyles, Typography
} from '@material-ui/core'

import ImportingCredit from './RoleImport'
// import RoleExisting from './RoleExisting'
import TabPanel from '../TabPanel'
import ImportHeader from './ImportHeader'
import Loading from '../Loading'

import {drillDown, unwindByKey} from 'deepdown'
import { useHistory } from 'react-router-dom'

import {
  computeImportXlsxBehaviors, reducerXlsxCreditToImportApi
} from '../../lib/models'

import {compile} from '../../lib/schema-utils'
import definitions from '../../lib/schema-xlsx-state.json'

import { v4 as uuidV4 } from 'uuid'
import { bulk } from 'dubcard'

const {BehaviorMode} = bulk

const validImport = compile({
  definitions,
  "$ref": "#/definitions/XlsxImport",
})

const regexCreditIndex = /credits\/(\d+)\/(\w+)\/.*/

const reducerParseImportSchemaErrors = (accum, error) => {
  const creditMatches = error.instancePath.match(regexCreditIndex)
  // console.log('reducerParseImportSchemaErrors, credit matches', creditMatches)
  if (creditMatches && creditMatches.length > 1) {
    const creditIndex = creditMatches[1]
    return {
      ...accum,
      creditsByIndex: {
        ...accum.creditsByIndex,
        [creditIndex]: [
          ...(accum.creditsByIndex[creditIndex] || []),
          error,
        ],
      },
    }
  }
  return accum
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  TabContainer: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(1),
  },
  TabRoot: {
    height: '100%',
    width: '100%',
  },
  DocumentActions: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  ImportHeader: {
    margin: theme.spacing(2),
  },
}))


const ImportReview = ({
  importedXlsx,
  // importedData,
  cancelImport,
  importCreditsFromXlsx,
  charactersByMpm,
  charactersBySeries,
  characters,
  talents,
  titles,
  searchTalent,
  getTitlesForTalent,
  getLocalizedTitle,
  LMTBcp47ToDescription,
  minScoreCharacter = 0.6,
  minScoreTalent = 0.8,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState(0)

  const [isComputing, setIsComputing] = useState(false)
  const [saveState, setSaveState] = useState({fetching: false, query: null, error: null, response: null})
  const [importBehaviors, setImportBehaviors] = useState({
    credits: [],
    charactersByName: {},
    talentsByName: {},
  })

  const mpm = drillDown(importedXlsx, 'data.mpm'.split('.'))
  const language = drillDown(importedXlsx, 'data.language'.split('.'))
  // const language = drillDown(importedXlsx, 'data.language'.split('.'))
  // const dataRoles = drillDown(importedXlsx, 'data.roles'.split('.'))
  const title = mpm && titles && titles[mpm]
  const season = title && titles && titles[title.mpmProductNumber]
  const series = titles && ((season && titles[season.mpmFamilyNumber]) || (title && titles[title.mpmFamilyNumber]))
  if ((series && charactersBySeries)) {
    console.log('=== ImportReview, charactersBySeries', charactersBySeries)
  }
  const franchiseCharacters = (series && charactersBySeries && charactersBySeries[series.mpm]) || (title && charactersByMpm && charactersByMpm[title.mpm])
  const unwoundFranchiseCharacterAKAs = useMemo(() => {
    return (franchiseCharacters && unwindByKey(franchiseCharacters, ['AKAs'])) || []
  }, [franchiseCharacters])

  const [targetDetailsState, setTargetDetailsState] = useState({ query: null, fetching: false, response: null, error: null })

  const schemaIsValid = validImport(importBehaviors)
  // if (!schemaIsValid) {
  //   console.error("behaviors", importBehaviors, ", are not valid", validImport.errors)
  // }
  const parsedErrors = (validImport.errors || []).reduce(reducerParseImportSchemaErrors, {creditsByIndex: {}})
  const enableImport = !targetDetailsState.fetching && !isComputing && schemaIsValid && !saveState.fetching
  const isLoading = targetDetailsState.fetching || isComputing

  const onChangeTabSelection = useCallback((...args) => {
    setSelectedTab(args[1])
  }, [setSelectedTab])

  const onChangeTalent = useCallback((credit, index) => entity => {
    console.log('ImportReview/onChangeTalent:', entity)

    setImportBehaviors(behaviors => ({
      ...behaviors,
      credits: [
        ...behaviors.credits.slice(0, index),
        {...credit, talent: {...credit.talent, entity, ...(!entity ? {} : {mode: BehaviorMode.USE_EXISTING})}},
        ...behaviors.credits.slice(1 + index),
      ],
    }))
  }, [setImportBehaviors])

  // const onChangeLocalizedCharacterName = useCallback(index => e => {
  //   console.log('ImportReview/onChangeLocalizedCharacterName', e.target.value)
  //   const role = {
  //     ...importedRoles[index],
  //     importedLocalizedCharacterName: e.target.value,
  //   }
  //   setImportedRoles([
  //     ...importedRoles.slice(0, index),
  //     role,
  //     ...importedRoles.slice(1 + index),
  //   ])
  // }, [importedRoles, setImportedRoles])

  // const onChangeForceCharacterLocalization = useCallback(index => e => {
  //   console.log('ImportReview/onChangeForceCharacterLocalization', e.target.value)
  //   const role = {
  //     ...importedRoles[index],
  //     forceCharacterLocalization: e.target.value,
  //   }
  //   setImportedRoles([
  //     ...importedRoles.slice(0, index),
  //     role,
  //     ...importedRoles.slice(1 + index),
  //   ])
  // }, [importedRoles, setImportedRoles])

  const onChangeBehaviorCharacter = useCallback((credit, index) => (e) => {
    const mode = e.target.value
    console.log('onChangeBehaviorCharacter, args', mode)
    setImportBehaviors(behaviors => ({
      ...behaviors,
      credits: [
        ...behaviors.credits.slice(0, index),
        {...credit, character: {...credit.character, mode}},
        ...behaviors.credits.slice(1 + index),
      ],
    }))
  }, [setImportBehaviors])

  const onChangeBehaviorTalent = useCallback((credit, index) => (e) => {
    const mode = e.target.value
    console.log('onChangeBehaviorTalent, args', mode)

    setImportBehaviors(behaviors => {
      let entity
      let newTalent
      let omitTalentName

      if (mode === BehaviorMode.USE_EXISTING) {
        // check if there is an obvious match?
        // or just force user to pick a talent?

        // either way, we should probably remove the entity that had been added to the CREATE batch,
        // unless another credit needs the same character

        // check if any other credit uses the SAME TALENT NAME, if not, then remove from CREATE batch.
        const filteredCreditsMatchTalentName = behaviors.credits.filter(c => c.xlsxRole.talentName === credit.xlsxRole.talentName)
        if (filteredCreditsMatchTalentName.length < 2) {
          omitTalentName = credit.xlsxRole.talentName
          entity = null  // null forces the user to pick a talent
        } else {
          // assume the same entity as the other credit with the SAME TALENT NAME
          const otherCredit = filteredCreditsMatchTalentName.find(c => (
            (c.role !== credit.role) ||
            (c.character?.entity.id !== credit.character?.entity?.id)
          ))

          entity = otherCredit.talent.entity
        }
      } else { // CREATE_NEW
        // this branch is to tell the system to use a NEW ENTITY,
        // but there is a small chance an existing entity already exists, i.e. another credit has the SAME TALENT NAME.
        // assuming another credit has the SAME TALENT NAME,
        //   if it needed to be CREATED, then an entity would have already been added to the CREATE batch.
        // assuming no other credit has the SAME TALENT NAME,
        //   then no entity should exist in the CREATE batch.

        // check if CREATE batch has an entity for this credit's talentName,
        // which means another credit exists for the same talentName
        if (!behaviors.talentsByName[credit.xlsxRole.talentName]) {
          newTalent = {id: uuidV4(), aka: uuidV4()}
          entity = newTalent
        }
      }

      const talentsByName = (!!omitTalentName)
        // need to omit, dont need to add newTalent
        ? Object.keys(behaviors.talentsByName).reduce((accum, key) => ({
          ...accum,
          ...(key === omitTalentName ? {} : {[key]: behaviors.talentsByName[key]}),
        }), {})
        // dont need to omit, possibly need to add newTalent
        : {
          ...behaviors.talentsByName,
          // insert new talent if needed
          ...(!newTalent ? {} : {[credit.xlsxRole.talentName]: newTalent}),
        }

      return {
        ...behaviors,
        talentsByName,
        credits: [
          ...behaviors.credits.slice(0, index),
          {...credit, talent: {entity, mode}},
          ...behaviors.credits.slice(1 + index),
        ],
      }
    })
  }, [setImportBehaviors])

  const onClickIgnore = useCallback((credit, index) => () => {
    const ignore = !credit.ignore
    setImportBehaviors(behaviors => ({
      ...behaviors,
      credits: [
        ...behaviors.credits.slice(0, index),
        {...credit, ignore},
        ...behaviors.credits.slice(1 + index),
      ],
    }))
  }, [setImportBehaviors])

  const onChangeCharacter = useCallback((credit, index) => (entity) => {
    console.log('ImportReview/onChangeCharacter:', entity)
    setImportBehaviors(behaviors => ({
      ...behaviors,
      credits: [
        ...behaviors.credits.slice(0, index),
        {...credit, character: {...credit.character, entity, ...(!entity ? {} : {mode: BehaviorMode.USE_EXISTING})}},
        ...behaviors.credits.slice(1 + index),
      ],
    }))
  }, [setImportBehaviors])

  const onClickCancelImport = useCallback(() => {
    cancelImport()
  }, [cancelImport])

  const onClickSaveImport = useCallback(() => {
    const initApi = {
      mpm: mpm,
      language: language,
      credits: [],
      characters: [],
      talents: [],
    }
    const importApi = importBehaviors.credits.reduce(reducerXlsxCreditToImportApi, initApi)
    setSaveState({query: importApi, fetching: true})
    importCreditsFromXlsx(importApi).then(response => {
      console.log('ImportReview/onClickSaveImport/saveImport, response', response)
      setSaveState(previous => ({...previous, fetching: false, response}))
      const route = `/mpm/${importApi.mpm}?lang=${importApi.language}`
      console.log('ImportReview/onClickSaveImport/saveImport, navigating to route', route)
      history.push(route)
    }).catch(error => {
      console.error('ImportReview/onClickSaveImport/saveImport, error', error)
      setSaveState(previous => ({...previous, fetching: false, error}))
      // TODO: display the error
    })
  }, [mpm, language, importBehaviors, history, importCreditsFromXlsx])

  useEffect(() => {
    if (!targetDetailsState.response || targetDetailsState.fetching) {
      return
    }

    let isMounted = true

    setIsComputing(true)

    computeImportXlsxBehaviors({
      unwoundFranchiseCharacterAKAs,
      importedXlsx,
      minScoreCharacter,
      minScoreTalent,
      searchTalent,
    }).then(behaviors => {
      if (!isMounted) {
        return
      }

      setImportBehaviors(behaviors)
      setIsComputing(false)
    })

    return () => {
      isMounted = false
    }
  }, [importedXlsx, targetDetailsState, unwoundFranchiseCharacterAKAs, minScoreCharacter, minScoreTalent, searchTalent])

  useEffect(() => {
    let mounted = true

    // fetch title details in case it is not in global app cache
    const before = {query: {mpm: [mpm]}, fetching: true}
    setTargetDetailsState(before)
    getLocalizedTitle(before.query).then(response => {
      if (!mounted) {
        return
      }

      console.log('ImportReview/getLocalizedTitle, response', response)
      setTargetDetailsState({...before, fetching: false, error: null, response})
      // const title = (response.title || []).find(t => t.mpm === mpm)
      // title && setExistingRoles(drillDown(title, ['original', 'roles']))
    }).catch(error => {
      if (!mounted) {
        return
      }

      console.error('ImportReview/getLocalizedTitle, error:', error)
      setTargetDetailsState({...before, fetching: false, error})
    })

    return () => {
      mounted = false
    }
  }, [getLocalizedTitle, mpm, setTargetDetailsState])

  return (
    <div id="import-review-container" className={classes.root}>
      <ImportHeader {...{importedXlsx, enableImport, titles, targetDetailsState, onClickSaveImport, onClickCancelImport, LMTBcp47ToDescription}} />

      <Grid container direction="row" alignItems="center" justify="flex-start" wrap="nowrap">
        <Grid container direction="row" alignItems="center" justify="flex-start" wrap="nowrap" className={classes.DocumentActions}>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={onChangeTabSelection}
          >
            <Tab label="Imported Roles" />
            {/* <Tab label="Existing Roles" /> */}
          </Tabs>
        </Grid>
      </Grid>

      <div className={classes.TabContainer}>
        <TabPanel selectedIndex={selectedTab} index={0}>
          <div className={classes.TabRoot}>
            {isLoading && (
              <Loading />
            )}
            {!isLoading && importBehaviors.credits.map((credit, i) => (
              <ImportingCredit
                key={`/import/credit/${i}`}
                {...{
                  credit,
                  errors: parsedErrors.creditsByIndex[i],
                  importBehaviors,
                  // talentsByName,
                  characters,
                  talents,
                  titles,
                  unwoundFranchiseCharacterAKAs,
                  // onChangeLocalizedCharacterName: onChangeLocalizedCharacterName(i),
                  // onChangeForceCharacterLocalization: onChangeForceCharacterLocalization(i),
                  onChangeBehaviorCharacter: onChangeBehaviorCharacter(credit, i),
                  onChangeBehaviorTalent: onChangeBehaviorTalent(credit, i),
                  onChangeCharacter: onChangeCharacter(credit, i),
                  onChangeTalent: onChangeTalent(credit, i),
                  onClickIgnore: onClickIgnore(credit, i),
                  getTitlesForTalent,
                  searchTalent,
                }}
              />
            ))}
          </div>
        </TabPanel>

        <TabPanel selectedIndex={selectedTab} index={1}>
          <div className={classes.TabRoot}>
            <Typography>TODO: display existing credits?</Typography>
            {/* {existingRoles.map((role, i) => (
            <RoleExisting
              key={`/import/existing-role/${i}`}
              {...{
                role,
                talents,
                // onChangeKeep: onChangeKeep(i),
              }} />
            ))} */}
          </div>
        </TabPanel>
      </div>

    </div>
  )
}

export default ImportReview
