"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const EnumPlatform = {
  HBOMAX: "HBOMAX"
};
const EnumDeliveryStatus = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR"
};
const _exports = {
  platforms: EnumPlatform,
  status: EnumDeliveryStatus
};
var _default = _exports;
exports.default = _default;