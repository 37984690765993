import React from 'react'
// import { Autocomplete } from '@material-ui/lab'
import Select from '@material-ui/core/Select'
import { 
  makeStyles,
  MenuItem
  // FormControl, Grid, InputLabel, TextField, Typography,
} from '@material-ui/core'
// import { drillDown, assignByKey } from 'deepdown'

// const useStylesAutocomplete = makeStyles(theme => ({
//   inputRoot: {
//     padding: `0 ${theme.spacing(1)}px`,
//     color: theme.palette.primary.contrastText,
//     "&:before": {
//       borderColor: theme.palette.primary.contrastText,
//     },
//     // this selector was determined by inspecing the dom tree,
//     // forcing on the :hover state for the dom node with this style applied,
//     // and determining which selector caused the border style.
//     "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
//       borderColor: theme.palette.primary.contrastText,
//     },
//   },
//   popupIndicator: {
//     color: theme.palette.primary.contrastText,
//   },
//   root: {
//     padding: `0 ${theme.spacing(1)}px`,
//     minWidth: 280,
//   },
// }))

// const useStylesOption = makeStyles(theme => ({
//   OptionRoot: {
//     maxWidth: 200,
//     minWidth: 200,
//   },
// }))

// const renderOption = LMTBcp47ToDescription => (option, state) => {
//   // const classes = useStylesOption()
//   const display = (option && LMTBcp47ToDescription && LMTBcp47ToDescription[option]) ? `${LMTBcp47ToDescription[option]} (${option})` : option
//   return (
// <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
//   <Typography>{display}</Typography>
// </Grid>
//   )
// }

// const renderInput = LMTBcp47ToDescription => (params) => {
//   const spot = ['inputProps', 'value']
//   const v = drillDown(params, spot) || ''
//   const display = (v && LMTBcp47ToDescription) ? `${LMTBcp47ToDescription[v]} (${v})` : v
//   assignByKey(params, spot, display)

//   return (
//     <TextField
//       {...params}
//       variant="standard"
//       size="small"
//       value={display}
//     />
//   )
// }

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: `0 ${theme.spacing(1)}px`,
    '& .MuiInput-underline:before': {
      borderColor: theme.palette.common.white,
    },
    '& .MuiInput-underline:hover:before': {
      borderColor: theme.palette.common.white,
    },
  },
  // container: {
  //   minWidth: 240,
  //   padding: `0 ${theme.spacing(1)}px`,
  // },
  // formControl: {
  //   margin: theme.spacing(1),
  // },
  // selectEmpty: {
  //   marginTop: theme.spacing(2),
  // },
}))

const useStylesSelect = makeStyles(theme => ({
  root: {
    margin: `0 ${theme.spacing(1)}px`,
    color: theme.palette.common.white,
  },
  icon: {
    color: theme.palette.common.white,
  },
}))

const useStylesInput = makeStyles(theme => ({
  root: {
    color: theme.palette.common.white,
  },
}))

const renderOptionSelect = (LMTBcp47ToDescription, keyprefix="/language-selector") => option => {
  const display = (option && LMTBcp47ToDescription && LMTBcp47ToDescription[option]) ? `${LMTBcp47ToDescription[option]} (${option})` : option

  return (
    <MenuItem key={`${keyprefix}/${option}`} value={option}>{display}</MenuItem>
  )
}

const LanguageSelector = ({
  LMTBcp47ToDescription,
  onChangeLanguage,
  lang,
  id,
}) => {
  // const classes = useStylesAutocomplete()
  const selectClasses = useStylesSelect()
  const inputClasses = useStylesInput()
  const classes = useStyles()
  const options = Object.keys(LMTBcp47ToDescription)
  const mapSelectOption = renderOptionSelect(LMTBcp47ToDescription)

  return (
    <div className={classes.wrapper} id={id}>
      <Select
        classes={selectClasses}
        value={lang}
        onChange={onChangeLanguage}
        inputProps={{classes: inputClasses}}
      >
        {options.map(mapSelectOption)}
      </Select>
    </div>
  )

  // return (
  // <Autocomplete
  //   debug
  //   disableClearable
  //   options={options}
  //   value={lang || ''}
  //   onChange={onChangeLanguage}
  //   renderInput={renderInput(LMTBcp47ToDescription)}
  //   renderOption={renderOption(LMTBcp47ToDescription)}
  //   classes={classes}
  // />
  // )
}

export default LanguageSelector
