"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultRasterSettings = exports.defaultLayout = exports.defaultRasterFormat = exports.RasterFormat = exports.defaultFont = exports.Fonts = void 0;

var _formatSettings = require("./format-settings");

const Fonts = {
  Roboto: "Roboto",
  RobotoSlab: "Roboto Slab",
  Tangerine: "Tangerine"
};
exports.Fonts = Fonts;
const defaultFont = Fonts.Roboto;
exports.defaultFont = defaultFont;
const RasterFormat = {
  PNG: "PNG",
  JPEG: "JPEG",
  TIFF: "TIFF"
};
exports.RasterFormat = RasterFormat;
const defaultRasterFormat = RasterFormat.PNG;
exports.defaultRasterFormat = defaultRasterFormat;
const defaultLayout = 'HD';
exports.defaultLayout = defaultLayout;
const defaultRasterSettings = {
  width: _formatSettings.HD.width,
  height: _formatSettings.HD.height,
  font: Fonts.Roboto,
  format: RasterFormat.PNG
};
exports.defaultRasterSettings = defaultRasterSettings;