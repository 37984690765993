"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BehaviorMode = void 0;
const BehaviorMode = {
  CREATE_NEW: "CREATE_NEW",
  USE_EXISTING: "USE_EXISTING"
};
exports.BehaviorMode = BehaviorMode;