"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.computeSvg = exports.calculateLayout = exports.talentSectionReducer = exports.talentReducer = exports.checkContentOnPage = exports.makeSectionHeadingElement = exports.makePageHeadingElement = exports.getBounds = exports.getLast = exports.makePage = exports.makeElementTalent = exports.makeElementTalentName = exports.makeElementTalentRole = exports.doTranslation = exports.makeElement = exports.enumAnchor = exports.getRole = void 0;

var _deepdown = require("deepdown");

var _roleTypes = require("./role-types");

var _models = require("./models");

const getRole = (credit, characters, translations, lang) => {
  const character = characters && credit.character && credit.character.id && characters[credit.character.id];
  const characterAka = character && character.AKAs && character.AKAs.find(aka => aka.id === credit.character.aka);
  const localizedAka = characterAka && characterAka.localizations && characterAka.localizations.find(l => l.language === lang);
  return _roleTypes.castRoles.includes(credit.role) && character ? localizedAka && localizedAka.value ? localizedAka.value : characterAka && (0, _deepdown.drillDown)(characterAka, ['original', 'value']) ? (0, _deepdown.drillDown)(characterAka, ['original', 'value']) : character.AKAs && character.AKAs.length > 0 ? character.AKAs[0].value || 'Unknown Character' : 'Unknown Character' : doTranslation(translations, lang, credit.role);
};

exports.getRole = getRole;
const enumAnchor = {
  start: 'start',
  middle: 'middle',
  end: 'end'
};
/**
 * constructor for scene element objects
 * 
 * @param {number} x position coordinate in scene space
 * @param {number} y position coordinate in scene space
 * @param {string} fill a color, by name or hex code, e.g. "white" or "#ffffff"
 * @param {string} anchor enum of values [start, middle, end]
 * @param {array} margin an array of lengths [top, right, bottom, left]
 * @param {string} text display value
 */

exports.enumAnchor = enumAnchor;

const makeElement = ({
  y,
  x,
  text,
  fontSize,
  anchor = enumAnchor.middle,
  fill = 'white',
  margin
}) => {
  return {
    y,
    x,
    text,
    fontSize,
    anchor,
    fill,
    margin
  };
};

exports.makeElement = makeElement;

const doTranslation = (translations, lang, source) => (0, _deepdown.drillDown)(translations, [lang, source]) || source;

exports.doTranslation = doTranslation;

const makeElementTalentRole = ({
  layout,
  locale
}, section, yEnd, role) => makeElement({
  x: 0.5 * layout.width - layout.roleSep,
  y: yEnd + layout.talentFontSize,
  text: section.disableUppercaseRole ? role : role.toLocaleUpperCase(locale.target),
  anchor: enumAnchor.end,
  fontSize: layout.talentFontSize,
  margin: [0, 0, layout.roleVerticalPad, 0],
  fill: layout.textFill
});

exports.makeElementTalentRole = makeElementTalentRole;

const makeElementTalentName = ({
  layout,
  locale
}, section, yEnd, fullName) => makeElement({
  x: 0.5 * layout.width + (section.hideRole ? 0 : layout.roleSep),
  y: yEnd + layout.talentFontSize,
  text: section.disableUppercaseRole ? fullName : fullName.toLocaleUpperCase(locale.target),
  anchor: section.hideRole ? enumAnchor.middle : enumAnchor.start,
  fontSize: layout.talentFontSize,
  margin: [0, 0, layout.roleVerticalPad, 0],
  fill: layout.textFill
});

exports.makeElementTalentName = makeElementTalentName;

const makeElementTalent = ({
  layout,
  locale
}, section, characters, translations, lang, yEnd, credit, talentsById) => {
  const elements = [];

  if (!section.hideRole && credit) {
    const roleString = getRole(credit, characters, translations, lang);
    elements.push(makeElementTalentRole({
      layout,
      locale
    }, section, yEnd, roleString));
  }

  const talentId = credit.talent && credit.talent.id;
  const talent = talentsById && talentId && talentsById[talentId];
  const talentAka = credit.talent && credit.talent.aka && talent && talent.AKAs.find(aka => aka.id === credit.talent.aka);
  const talentName = talent && talentAka ? talentAka.value : talent && talent.AKAs.length > 0 ? talent.AKAs[0].value : undefined;

  if (talentName) {
    elements.push(makeElementTalentName({
      layout,
      locale
    }, section, yEnd, talentName || ''));
  }

  return elements;
};

exports.makeElementTalent = makeElementTalent;

const makePage = ({
  layout,
  section,
  translations,
  lang
}) => {
  const pageHeading = doTranslation(translations, lang, section.heading);
  const subHeading = section.subHeading && doTranslation(translations, lang, section.subHeading);
  const elementPageHeading = makePageHeadingElement({
    layout
  }, layout.verticalPad, pageHeading);
  const yEnd = getBounds(elementPageHeading).bottomRight.y;
  const elementSectionSubHeading = subHeading && makeSectionHeadingElement({
    layout
  }, yEnd, subHeading);
  return {
    elements: [elementPageHeading, ...(!subHeading ? [] : [elementSectionSubHeading])]
  };
};

exports.makePage = makePage;

const getLast = arr => arr[arr.length - 1];

exports.getLast = getLast;

const getBounds = element => ({
  topLeft: {
    y: element.y - element.fontSize + element.margin[0]
  },
  bottomRight: {
    y: element.y + element.margin[2] + element.margin[0]
  }
});

exports.getBounds = getBounds;

const makePageHeadingElement = ({
  layout
}, yEnd, pageHeading) => makeElement({
  x: 0.5 * layout.width,
  y: yEnd + layout.headingFontSize,
  text: pageHeading,
  anchor: enumAnchor.middle,
  fontSize: layout.headingFontSize,
  margin: [0, 0, layout.headingVerticalPad, 0],
  fill: layout.textFill
});

exports.makePageHeadingElement = makePageHeadingElement;

const makeSectionHeadingElement = ({
  layout
}, yEnd, pageHeading) => makeElement({
  x: 0.5 * layout.width,
  y: yEnd + layout.headingFontSize,
  text: pageHeading,
  anchor: enumAnchor.middle,
  fontSize: layout.headingFontSize,
  margin: [0, 0, layout.headingVerticalPad, 0],
  fill: layout.textFill
}); // returns a page that is safe to add a new element to


exports.makeSectionHeadingElement = makeSectionHeadingElement;

const checkContentOnPage = ({
  layout,
  section,
  accum,
  translations,
  lang
}) => {
  let currentPage, accumResult;
  const lastPage = getLast(accum);
  currentPage = lastPage;
  accumResult = accum;
  const lastElement = getLast(lastPage.elements);
  const yEnd = getBounds(lastElement).bottomRight.y; // check height relative to last page if we need a new page

  const pageLowerLimit = layout.height - layout.verticalPad;
  const contentHeight = layout.talentFontSize;

  if (yEnd + contentHeight > pageLowerLimit) {
    const newPage = makePage({
      layout,
      section,
      translations,
      lang
    }); // performs the reducer output, see talentReducer

    currentPage = newPage;
    accumResult = [...accum, newPage];
  }

  return {
    currentPage,
    accumResult
  };
};

exports.checkContentOnPage = checkContentOnPage;

const talentReducer = (props, section) => (accum, role) => {
  const {
    settings,
    characters,
    talentsById,
    translations,
    lang
  } = props;
  const {
    layout
  } = settings;
  const {
    currentPage,
    accumResult
  } = checkContentOnPage({
    layout,
    section,
    accum,
    translations,
    lang
  });
  const lastElement = getLast(currentPage.elements);
  const yEnd = getBounds(lastElement).bottomRight.y;
  currentPage.elements = [...currentPage.elements, ...makeElementTalent(settings, section, characters, translations, lang, yEnd, role, talentsById)];
  return accumResult;
};

exports.talentReducer = talentReducer;

const talentSectionReducer = props => (accum, section) => {
  const {
    settings,
    translations,
    lang,
    sortedRoles,
    talentsById,
    characters
  } = props; // choose which roles to display

  const filteredRoles = !section.roleFilter ? sortedRoles : sortedRoles.filter((0, _models.filterRoles)(section.roleFilter));

  if (filteredRoles.length === 0) {
    return accum;
  }

  const {
    layout,
    locale,
    card
  } = settings;
  const clientForceNewPage = !(card !== null && card !== void 0 && card.combine);
  let currentPage = getLast(accum);
  let accumResult = accum; // make a new page for each section

  if (section.forceNewPage || clientForceNewPage || accum.length === 0) {
    currentPage = makePage({
      layout,
      section,
      translations,
      lang
    });
    accumResult = [...accum, currentPage];
  } else {
    // make sub-heading element
    const subHeading = section.subHeading && doTranslation(translations, lang, section.subHeading);

    if (subHeading) {
      // select most recent element's y coordinate
      const yEnd = currentPage.elements.length > 0 ? getBounds(getLast(currentPage.elements)).bottomRight.y : layout.verticalPad; // look ahead at what will be needed, to see if it will fit on the current page

      const subHeadingElement = makeSectionHeadingElement(settings, yEnd + layout.sectionVerticalPad, subHeading);
      const yEndSubHeading = getBounds(subHeadingElement).bottomRight.y; // make sure at least one credit will be visible under the subheading

      const nextCredit = filteredRoles[0];
      const nextCreditElements = makeElementTalent({
        layout,
        locale
      }, section, characters, translations, lang, yEndSubHeading, nextCredit, talentsById);

      if (nextCreditElements.length > 0) {
        const yEndNextCredit = getBounds(getLast(nextCreditElements)).bottomRight.y; // add the subheading if it fits

        const pageLowerLimit = layout.height - layout.verticalPad;

        if (yEndNextCredit < pageLowerLimit) {
          // TODO: find alternative to this mutation
          currentPage.elements.push(subHeadingElement);
        } else {
          currentPage = makePage({
            layout,
            section,
            translations,
            lang
          });
          accumResult = [...accum, currentPage];
        }
      }
    }
  }

  return filteredRoles.reduce(talentReducer(props, section), accumResult);
};

exports.talentSectionReducer = talentSectionReducer;

const calculateLayout = ({
  sections,
  ...otherProps
}) => {
  return sections.reduce(talentSectionReducer(otherProps), []);
};

exports.calculateLayout = calculateLayout;

const computeSvgElement = ({
  x,
  y,
  fontSize,
  anchor,
  fill,
  text
}) => `<text x="${x}" y="${y}" font-size="${fontSize}" text-anchor="${anchor}" fill="${fill}">${text}</text>`;

const computeSvg = (elements, fontFamily, settings = {}, id) => {
  const {
    width,
    height,
    bgFill
  } = settings;
  return `
<svg ${id ? `id="${id}"` : ''}
  version="1.1"
  baseProfile="full"
  viewBox="${[0, 0, width, height].join(' ')}"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink">

    <style type="text/css">
      text { font-family: ${fontFamily} }
    </style>

    <rect width="100%" height="100%" fill="${bgFill}" />
    ${elements.map(computeSvgElement).join('\n    ')}
</svg>
`;
};

exports.computeSvg = computeSvg;