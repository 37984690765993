/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTalent = /* GraphQL */ `
  mutation CreateTalent($input: InputCreateTalent!) {
    createTalent(input: $input) {
      id
      AKAs {
        id
        value
        language
      }
    }
  }
`;
export const addAkasToTalent = /* GraphQL */ `
  mutation AddAkasToTalent($input: InputAddAkasToTalent!) {
    addAkasToTalent(input: $input) {
      id
      AKAs {
        id
        value
        language
      }
    }
  }
`;
export const updateTalentAka = /* GraphQL */ `
  mutation UpdateTalentAka($input: InputUpdateTalentAka!) {
    updateTalentAka(input: $input) {
      id
      AKAs {
        id
        value
        language
      }
    }
  }
`;
export const importCharactersFromAtom = /* GraphQL */ `
  mutation ImportCharactersFromAtom($input: InputImportCharactersFromAtom!) {
    importCharactersFromAtom(input: $input) {
      hits {
        mpm
      }
      title {
        mpm
        mpmProductNumber
        mpmFamilyNumber
        type
        originallyAiredAs
        originalReleaseYear
        original {
          mpm
          language
          title
          credits {
            role
            talentName
            characterName
            billingOrder
          }
        }
        localized {
          mpm
          language
          title
          credits {
            role
            talent {
              id
              aka
            }
            character {
              id
              aka
            }
            billingOrder
          }
          overrides {
            mpm
            language
            credits {
              match {
                role
                talent {
                  id
                }
                character {
                  id
                }
              }
              value {
                billingOrder
                talent {
                  id
                  aka
                }
                hidden
              }
            }
          }
        }
        poster {
          assetId
          assetSubType
          copyrightNotice
          renditions {
            renditionName
            url
          }
          usageTerritory
        }
        parentTitles {
          parentMpmNumber
          parentContentType
          parentOriginallyAiredAs
          isDefault
        }
      }
      character {
        id
        series
        AKAs {
          id
          mpm
          original {
            language
            value
          }
          localizations {
            language
            value
          }
        }
      }
      talent {
        id
        AKAs {
          id
          value
          language
        }
      }
    }
  }
`;
export const createCharacter = /* GraphQL */ `
  mutation CreateCharacter($input: InputCreateCharacter!) {
    createCharacter(input: $input) {
      id
      series
      AKAs {
        id
        mpm
        original {
          language
          value
        }
        localizations {
          language
          value
        }
      }
    }
  }
`;
export const addAkasToCharacter = /* GraphQL */ `
  mutation AddAkasToCharacter($input: InputAddAkasToCharacter!) {
    addAkasToCharacter(input: $input) {
      id
      series
      AKAs {
        id
        mpm
        original {
          language
          value
        }
        localizations {
          language
          value
        }
      }
    }
  }
`;
export const addTitlesToCharacterAka = /* GraphQL */ `
  mutation AddTitlesToCharacterAka($input: InputAddTitlesToCharacterAka!) {
    addTitlesToCharacterAka(input: $input) {
      id
      series
      AKAs {
        id
        mpm
        original {
          language
          value
        }
        localizations {
          language
          value
        }
      }
    }
  }
`;
export const removeTitlesFromCharacterAka = /* GraphQL */ `
  mutation RemoveTitlesFromCharacterAka(
    $input: InputRemoveTitlesFromCharacterAka!
  ) {
    removeTitlesFromCharacterAka(input: $input) {
      id
      series
      AKAs {
        id
        mpm
        original {
          language
          value
        }
        localizations {
          language
          value
        }
      }
    }
  }
`;
export const updateCharacterAkaOriginal = /* GraphQL */ `
  mutation UpdateCharacterAkaOriginal(
    $input: InputUpdateCharacterAkaOriginal!
  ) {
    updateCharacterAkaOriginal(input: $input) {
      id
      series
      AKAs {
        id
        mpm
        original {
          language
          value
        }
        localizations {
          language
          value
        }
      }
    }
  }
`;
export const updateLocalizationToCharacterAka = /* GraphQL */ `
  mutation UpdateLocalizationToCharacterAka(
    $input: InputUpdateLocalizationToCharacterAka!
  ) {
    updateLocalizationToCharacterAka(input: $input) {
      id
      series
      AKAs {
        id
        mpm
        original {
          language
          value
        }
        localizations {
          language
          value
        }
      }
    }
  }
`;
export const importCreditsFromXlsx = /* GraphQL */ `
  mutation ImportCreditsFromXlsx($input: InputImportCreditsFromXlsx!) {
    importCreditsFromXlsx(input: $input) {
      hits {
        mpm
      }
      title {
        mpm
        mpmProductNumber
        mpmFamilyNumber
        type
        originallyAiredAs
        originalReleaseYear
        original {
          mpm
          language
          title
          credits {
            role
            talentName
            characterName
            billingOrder
          }
        }
        localized {
          mpm
          language
          title
          credits {
            role
            talent {
              id
              aka
            }
            character {
              id
              aka
            }
            billingOrder
          }
          overrides {
            mpm
            language
            credits {
              match {
                role
                talent {
                  id
                }
                character {
                  id
                }
              }
              value {
                billingOrder
                talent {
                  id
                  aka
                }
                hidden
              }
            }
          }
        }
        poster {
          assetId
          assetSubType
          copyrightNotice
          renditions {
            renditionName
            url
          }
          usageTerritory
        }
        parentTitles {
          parentMpmNumber
          parentContentType
          parentOriginallyAiredAs
          isDefault
        }
      }
      character {
        id
        series
        AKAs {
          id
          mpm
          original {
            language
            value
          }
          localizations {
            language
            value
          }
        }
      }
      talent {
        id
        AKAs {
          id
          value
          language
        }
      }
    }
  }
`;
export const addCreditsToLocalizedTitle = /* GraphQL */ `
  mutation AddCreditsToLocalizedTitle($input: InputAddCredits!) {
    addCreditsToLocalizedTitle(input: $input) {
      mpm
      language
      title
      credits {
        role
        talent {
          id
          aka
        }
        character {
          id
          aka
        }
        billingOrder
      }
      overrides {
        mpm
        language
        credits {
          match {
            role
            talent {
              id
            }
            character {
              id
            }
          }
          value {
            billingOrder
            talent {
              id
              aka
            }
            hidden
          }
        }
      }
    }
  }
`;
export const removeCreditFromLocalizedTitle = /* GraphQL */ `
  mutation RemoveCreditFromLocalizedTitle($input: InputRemoveCreditLocalized!) {
    removeCreditFromLocalizedTitle(input: $input) {
      mpm
      language
      title
      credits {
        role
        talent {
          id
          aka
        }
        character {
          id
          aka
        }
        billingOrder
      }
      overrides {
        mpm
        language
        credits {
          match {
            role
            talent {
              id
            }
            character {
              id
            }
          }
          value {
            billingOrder
            talent {
              id
              aka
            }
            hidden
          }
        }
      }
    }
  }
`;
export const updateTalentForCharacterCredit = /* GraphQL */ `
  mutation UpdateTalentForCharacterCredit(
    $input: InputUpdateTalentForCharacterCredit!
  ) {
    updateTalentForCharacterCredit(input: $input) {
      mpm
      language
      title
      credits {
        role
        talent {
          id
          aka
        }
        character {
          id
          aka
        }
        billingOrder
      }
      overrides {
        mpm
        language
        credits {
          match {
            role
            talent {
              id
            }
            character {
              id
            }
          }
          value {
            billingOrder
            talent {
              id
              aka
            }
            hidden
          }
        }
      }
    }
  }
`;
export const updateBillingForOriginalCharacterCredit = /* GraphQL */ `
  mutation UpdateBillingForOriginalCharacterCredit(
    $input: InputUpdateBillingForOriginalCharacterCredit!
  ) {
    updateBillingForOriginalCharacterCredit(input: $input) {
      mpm
      language
      title
      credits {
        role
        talent {
          id
          aka
        }
        character {
          id
          aka
        }
        billingOrder
      }
      overrides {
        mpm
        language
        credits {
          match {
            role
            talent {
              id
            }
            character {
              id
            }
          }
          value {
            billingOrder
            talent {
              id
              aka
            }
            hidden
          }
        }
      }
    }
  }
`;
export const updateBillingForLocalizedCredit = /* GraphQL */ `
  mutation UpdateBillingForLocalizedCredit(
    $input: InputUpdateBillingForLocalizedCredit!
  ) {
    updateBillingForLocalizedCredit(input: $input) {
      mpm
      language
      title
      credits {
        role
        talent {
          id
          aka
        }
        character {
          id
          aka
        }
        billingOrder
      }
      overrides {
        mpm
        language
        credits {
          match {
            role
            talent {
              id
            }
            character {
              id
            }
          }
          value {
            billingOrder
            talent {
              id
              aka
            }
            hidden
          }
        }
      }
    }
  }
`;
export const updateEpisodeOverrideForSeason = /* GraphQL */ `
  mutation UpdateEpisodeOverrideForSeason(
    $input: InputUpdateEpisodeOverrideForSeason!
  ) {
    updateEpisodeOverrideForSeason(input: $input) {
      mpm
      language
      title
      credits {
        role
        talent {
          id
          aka
        }
        character {
          id
          aka
        }
        billingOrder
      }
      overrides {
        mpm
        language
        credits {
          match {
            role
            talent {
              id
            }
            character {
              id
            }
          }
          value {
            billingOrder
            talent {
              id
              aka
            }
            hidden
          }
        }
      }
    }
  }
`;
export const removeEpisodeOverrideForSeason = /* GraphQL */ `
  mutation RemoveEpisodeOverrideForSeason(
    $input: InputRemoveEpisodeOverrideForSeason!
  ) {
    removeEpisodeOverrideForSeason(input: $input) {
      mpm
      language
      title
      credits {
        role
        talent {
          id
          aka
        }
        character {
          id
          aka
        }
        billingOrder
      }
      overrides {
        mpm
        language
        credits {
          match {
            role
            talent {
              id
            }
            character {
              id
            }
          }
          value {
            billingOrder
            talent {
              id
              aka
            }
            hidden
          }
        }
      }
    }
  }
`;
export const renderRasterBySvg = /* GraphQL */ `
  mutation RenderRasterBySvg($input: InputRenderRasterBySvg) {
    renderRasterBySvg(input: $input) {
      base64
    }
  }
`;
export const createDelivery = /* GraphQL */ `
  mutation CreateDelivery($input: InputCreateDelivery) {
    createDelivery(input: $input) {
      id
      createdOn
      submittedByUserId
      updatedOn
      status
      message
      platform
      settings {
        format
        width
        height
      }
      items {
        index
        svg
        storage {
          bucket
          path
        }
      }
    }
  }
`;
