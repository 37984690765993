import { fade, Grid, makeStyles, Paper } from '@material-ui/core'

import ImportingTalent from './ImportingTalent'
import ImportingActions from './ImportingActions'
import ImportingRole from './ImportingRole'
import ImportingBilling from './ImportingBilling'

import clsx from 'clsx'

import {compile} from '../../lib/schema-utils'
import definitions from '../../lib/schema/definitions.json'


const validImportCredit = compile({
  "$ref": "#/definitions/ImportedTitleCredit",
  definitions,
})


const useStyles = makeStyles(theme => ({
  ImportingCreditPaper: {
    // minHeight: (54 + 2*theme.spacing(1)),
    margin: `${theme.spacing(1)}px 0`,
  },
  ImportingCredit: {
    padding: theme.spacing(1),
    gap: 8,
  },
  TalentName: {
    height: '100%',
    minWidth: 300,
    maxWidth: 300,
    margin: `0 ${theme.spacing(1)}px`,
  },
  TalentNameSwitchLabel: {
    lineHeight: 1.0,
  },
  CenterInput: {
    textAlign: 'center',
  },
  LeftInput: {
    textAlign: 'left',
  },
  IgnoreImport: {
    backgroundColor: theme.palette.grey[400],
  },
  InputPrimary: {
    backgroundColor: theme.palette.primary.light,
  },
  InputSecondary: {
    backgroundColor: theme.palette.secondary.light,
  },
  ImportingCreditInvalid: {
    // border: `2px solid ${theme.palette.error.main}`
    backgroundColor: fade(theme.palette.error.main, 0.25),
  },
  expand: {
    flex: 1,
  },
  gutter: {
    minWidth: 48,
    maxWidth: 48,
    width: 48,
  },
}))


const useStylesBehavior = makeStyles(theme => ({
  XlsxBehaviorCreateNew: {
    backgroundColor: fade(theme.palette.secondary.main, 0.25),
  },
  XlsxBehaviorUseExisting: {
    backgroundColor: fade(theme.palette.primary.main, 0.25),
  },
  XlsxBehaviorIgnore: {
    backgroundColor: fade(theme.palette.grey[900], 0.25),
  },
}))


const ImportingCredit = ({
  credit,
  errors,
  importBehaviors,
  characters,
  talents,
  unwoundFranchiseCharacterAKAs,
  searchTalent,
  onChangeCharacter,
  onChangeTalent,
  onClickIgnore,
  onChangeBehaviorCharacter,
  onChangeBehaviorTalent,
}) => {
  const classes = useStyles()
  const classesBehavior = useStylesBehavior()

  const isValid = !(errors && errors.length > 0)
  if (!isValid) {
    console.log('ImportingCredit validation errors', validImportCredit.errors, 'for credit:', credit)
  }

  return (
    <Paper className={clsx(classes.ImportingCreditPaper, credit.ignore ? classes.IgnoreImport : (!isValid ? classes.ImportingCreditInvalid : ''))}>
      <Grid container direction="row" alignItems="flex-end" justify="flex-start" wrap="nowrap" className={classes.ImportingCredit}>
        <ImportingBilling credit={credit} classes={{root: classes.gutter}} />
        <ImportingRole {...{credit, characters, classesBehavior, unwoundFranchiseCharacterAKAs, onChangeCharacter, onChangeBehaviorCharacter}} />
        <ImportingTalent {...{credit, importBehaviors, talents, onChangeTalent, classesBehavior, onChangeBehaviorTalent, searchTalent}} />
        <Grid className={classes.gutter}>
          <ImportingActions {...{credit, onClickIgnore}} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ImportingCredit
