"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.castRoles = exports.RoleTypes = void 0;
const RoleTypes = {
  DUBBING_VOICE: "Dubbing Voice",
  DUBBING_STUDIO: "Dubbing Studio",
  DUBBING_DIRECTOR: "Dubbing Director",
  DUBBING_TRANSLATOR: "Translator",
  DUBBING_ADAPTATION: "Adaptation",
  DUBBING_EDITOR: "Editor",
  DUBBING_SUPERVISOR: "Supervisor",
  DUBBING_ENGINEER: "Engineer",
  DUBBING_RECORDING_TECHNICIAN: "Recording Technician",
  DUBBING_MIXER: "Mixer"
};
exports.RoleTypes = RoleTypes;
const castRoles = [RoleTypes.DUBBING_VOICE]; // export const studioRoles = Object.keys(EnumCreditRole)
//   .map(key => EnumCreditRole[key])
//   .filter(role => !castRoles.includes(role))

exports.castRoles = castRoles;