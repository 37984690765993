import { useEffect, useRef } from 'react'

import {
  Button,
  makeStyles,
  TextField
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  HeaderButton: {
    margin: theme.spacing(1),
  },
}))

const CharacterFilterInput = ({
  filter,
  onChangeFilterQuery,
}) => {
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.focus()
  }, [inputRef])

  return (
    <TextField
      id="add-character-filter"
      label="Find Character"
      inputRef={inputRef}
      value={filter.query || ''}
      {...{onChange: onChangeFilterQuery}}
    />
  )
}

const FranchiseCharacterHeader = ({
  filter,
  onChangeFilterQuery,
  addCharacterDisabled,
  onClickAddNewCharacter,
  onClickCancelAddCharacter,
}) => {
  const classes = useStyles()
  return (
    <>
      <CharacterFilterInput {...{filter, onChangeFilterQuery}} />
      <Button
        id="add-new-character"
        variant="contained"
        color="secondary"
        disabled={addCharacterDisabled}
        onClick={onClickAddNewCharacter}
        size="small"
        className={classes.HeaderButton}
      >+ADD</Button>
      <Button
        id="cancel-add-character"
        variant="text"
        onClick={onClickCancelAddCharacter}
        size="small"
        className={classes.HeaderButton}
      >CANCEL</Button>
    </>
  )
}

export default FranchiseCharacterHeader
