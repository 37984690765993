
import {
  makeStyles
} from '@material-ui/core'

import Loading from '../Loading'
import EmptyList from '../EmptyList'
import ErrorDisplay from '../ErrorDisplay'

import AtomCharacterListItem from './AtomCharacterListItem'

import { formatGraphQlError } from '../../lib/models'


const useStyles = makeStyles(({
  AtomImportContent: {
    height: '100%',
    overflow: 'auto',
  },
}))


const billingOrderSort = (a, b) => {
  return a.billingOrder - b.billingOrder
}

const AtomImportContent = ({
  atomImport,
  atomCharacters,
  franchiseCharacters,
  atomImportData,
  onClickIgnoreItem,
  onChangeMode,
  onChangeCharacter,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.AtomImportContent}>
      {(atomImport.error)
        ? <ErrorDisplay message={formatGraphQlError(atomImport.error)} />
        : (atomCharacters.fetching)
          ? <Loading />
          : (atomCharacters.error)
            ? <ErrorDisplay message={atomCharacters.error.message || JSON.stringify(atomCharacters.error)} />
            : (!atomCharacters.results || (atomCharacters.results.length === 0))
              ? <EmptyList label="Atom provided no characters" />
              : (atomCharacters.results.sort(billingOrderSort).map(data => {
                const behavior = atomImportData[data.characterName]

                return (
                  <AtomCharacterListItem
                    key={`/original/atom/${data.characterName}`}
                    {...{
                      data,
                      franchiseCharacters,
                      behavior,
                      onClickIgnoreItem: onClickIgnoreItem(data),
                      onChangeMode: onChangeMode(data),
                      onChangeCharacter: onChangeCharacter(data),
                    }}
                  />)
              }))
      }
    </div>
  )
}

export default AtomImportContent
