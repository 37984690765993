import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton, TextField } from '@material-ui/core'

import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
  },
  pageInput: {
    '& input': {
      minWidth: 20,
      maxWidth: 30,
      textAlign: 'center',
    },
  },
}))

const rotateNumber = (val, incr, max) => {
  let result = val + incr
  if (result < 0) {
    result = max - 1
  }
  return result % max
}

const clampNumber = (val, min, max) => {
  if (val < min) {
    return min
  }
  if (val >= max) {
    return max - 1
  }
  return val
}

const PageSelector = ({disabled, numPages, selectedPage, setSelectedPage}) => {
  const classes = useStyles()

  const handleNav = direction => (/*e*/) => {
    const modded = rotateNumber(selectedPage, direction, numPages)
    setSelectedPage(modded)
  }

  const handleText = (e) => {
    const userTyped = Number(e.target.value)
    if (isNaN(userTyped)) {
      return
    }
    console.log('page select input:', userTyped, typeof userTyped)
    setSelectedPage(clampNumber(userTyped - 1, 0, numPages))
  }

  return (
    <Grid container direction="row" wrap="nowrap" className={classes.container} justify="center" alignItems="center">
      <IconButton disabled={disabled} size="small" onClick={handleNav(-1)}><NavigateBeforeIcon /></IconButton>
      <TextField disabled={disabled} value={1 + selectedPage} onChange={handleText} InputProps={{className: classes.pageInput}} inputProps={{min: 1}} />
      <IconButton  disabled={disabled} size="small" onClick={handleNav(1)}><NavigateNextIcon /></IconButton>
    </Grid>
  )
}

export default PageSelector
