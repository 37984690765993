"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matchCredit = exports.organizeCreditsForEditor = exports.getSortedRoles = exports.billingSort = exports.defaultOverrideHash = exports.defaultOverrideHashCreditElements = exports.defaultOverrideHashOverrideElements = exports.defaultFallbackHash = exports.reduceCharactersByMpm = exports.filterRoles = exports.indexByHash = exports.reducerCharacters = exports.reducerTalents = exports.reducerTitles = void 0;

var _deepdown = require("deepdown");

var _roleTypes = require("./role-types");

const reducerTitles = (accum, t) => ({ ...accum,
  [t.mpm]: t
});

exports.reducerTitles = reducerTitles;

const reducerTalents = (accum, t) => ({ ...accum,
  [t.id]: t
});

exports.reducerTalents = reducerTalents;

const reducerCharacters = (accum, c) => ({ ...accum,
  [c.id]: c
});

exports.reducerCharacters = reducerCharacters;

const indexByHash = (array, hash) => {
  const index = array.reduce((accum, elem) => {
    const h = hash(elem);

    if (!accum[h]) {
      // add new array
      return { ...accum,
        [h]: [elem]
      };
    } // append existing array


    return { ...accum,
      [h]: [...accum[h], elem]
    };
  }, {});
  return index;
};

exports.indexByHash = indexByHash;

const filterRoles = ({
  includes,
  excludes,
  checkCharacter
}) => credit => {
  return (includes ? includes.includes(credit.role) : true) && (excludes ? !excludes.includes(credit.role) : true) && (checkCharacter ? !!(0, _deepdown.drillDown)(credit, ['character', 'id']) === checkCharacter.value : true);
};

exports.filterRoles = filterRoles;

const reducerCharactersByMpm = (charAkasGroupedByMpm, charsGroupedById) => (accum, mpm) => {
  const existingCharsAtMpm = accum[mpm] || [];
  const finalCharsAtMpm = [...existingCharsAtMpm];
  const existingGroupedById = (0, _deepdown.indexByKey)(existingCharsAtMpm, ['id']);
  const charAkasCandidatesAtMpm = charAkasGroupedByMpm[mpm];
  const candidatesAtMpmGroupedById = (0, _deepdown.indexByKey)(charAkasCandidatesAtMpm, ['id']);
  Object.keys(candidatesAtMpmGroupedById).forEach(id => {
    if (!existingGroupedById[id]) {
      const needToPush = charsGroupedById[id];
      needToPush.forEach(needTo => {
        finalCharsAtMpm.push(needTo);
      });
    }
  });
  return { ...accum,
    [mpm]: finalCharsAtMpm
  };
};

const reduceCharactersByMpm = (charactersByMpm, characters) => {
  const charsGroupedById = (0, _deepdown.indexByKey)(characters, ['id']);
  const unwoundCharAkas = (0, _deepdown.unwindByKey)(characters, ['AKAs']);
  const unwoundCharacterAkasMpms = (0, _deepdown.unwindByKey)(unwoundCharAkas, ['AKAs', 'mpm']);
  const charAkasGroupedByMpm = (0, _deepdown.indexByKey)(unwoundCharacterAkasMpms, ['AKAs', 'mpm']);
  return Object.keys(charAkasGroupedByMpm).reduce(reducerCharactersByMpm(charAkasGroupedByMpm, charsGroupedById), charactersByMpm);
};

exports.reduceCharactersByMpm = reduceCharactersByMpm;

const defaultFallbackHash = credit => {
  return (0, _deepdown.drillDown)(credit, ['talent', 'id']);
};

exports.defaultFallbackHash = defaultFallbackHash;
const defaultOverrideHashSeparator = '/';
const defaultOverrideHashKeyValSep = '::';
const defaultOverrideHashOverrideElements = [{
  key: 'role',
  path: 'match.role'.split('.')
}, {
  key: 'talent',
  path: 'match.talent.id'.split('.')
}, {
  key: 'character',
  path: 'match.character.id'.split('.')
}];
exports.defaultOverrideHashOverrideElements = defaultOverrideHashOverrideElements;
const defaultOverrideHashCreditElements = [{
  key: 'role',
  path: 'role'.split('.')
}, {
  key: 'talent',
  path: 'talent.id'.split('.')
}, {
  key: 'character',
  path: 'character.id'.split('.')
}];
exports.defaultOverrideHashCreditElements = defaultOverrideHashCreditElements;

const defaultOverrideHash = (elementsToCheck = defaultOverrideHashCreditElements) => overrideCredit => {
  return elementsToCheck.map(elem => elem.key + defaultOverrideHashKeyValSep + (0, _deepdown.drillDown)(overrideCredit, elem.path)).join(defaultOverrideHashSeparator);
};

exports.defaultOverrideHash = defaultOverrideHash;

const hasCharacter = credit => {
  return !!(0, _deepdown.drillDown)(credit, ['character', 'id']);
}; // sort the credits
//
// the value of role is not important;
// assume credits are within the same group for sorting,
// e.g. "all crew roles", or "all cast roles"


const billingSort = ({
  ascending = true,
  // hash function used to populate indexOverrides
  overrideHash = defaultOverrideHash(),
  indexOverrides = {},
  // hash function used to populate indexFallbacks
  fallbackHash = defaultFallbackHash,
  indexFallbacks = {}
} = {}) => (a, b) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort

  /*
  - If compareFunction(a, b) returns less than 0, leave a and b unchanged.
  - If compareFunction(a, b) returns 0, leave a and b unchanged with respect to each other, but sorted with respect to all different elements.
  - If compareFunction(a, b) returns greater than 0, sort b before a.
  */
  const aOverride = (0, _deepdown.drillDown)(indexOverrides, [overrideHash(a), 0]);
  const bOverride = (0, _deepdown.drillDown)(indexOverrides, [overrideHash(b), 0]);
  const aFallback = (0, _deepdown.drillDown)(indexFallbacks, [fallbackHash(a), 0]);
  const bFallback = (0, _deepdown.drillDown)(indexFallbacks, [fallbackHash(b), 0]); // specificity, left to right

  const aBilling = aOverride && aOverride.value && aOverride.value.billingOrder || a.billingOrder || aFallback && aFallback.billingOrder;
  const bBilling = bOverride && bOverride.value && bOverride.value.billingOrder || b.billingOrder || bFallback && bFallback.billingOrder;

  if (!aBilling && !bBilling) {
    return 0;
  }

  if (!aBilling) {
    return ascending ? 1 : -1;
  }

  if (!bBilling) {
    return ascending ? -1 : 1; // return 0
  }

  if (aBilling === bBilling) {
    return 0;
  }

  return aBilling < bBilling ? ascending ? -1 : 1 : ascending ? 1 : -1;
};

exports.billingSort = billingSort;

const preventHidden = ({
  indexOverrideMatches = {},
  overrideHashFn = defaultOverrideHash(defaultOverrideHashCreditElements)
} = {}) => credit => {
  const hidden = indexOverrideMatches && (0, _deepdown.drillDown)(indexOverrideMatches, [overrideHashFn(credit), 0, 'value', 'hidden']);
  return !hidden;
};

const swapOverrideTalent = ({
  indexOverrideMatches = {},
  overrideHashFn = defaultOverrideHash(defaultOverrideHashCreditElements)
} = {}) => credit => {
  const talent = indexOverrideMatches && (0, _deepdown.drillDown)(indexOverrideMatches, [overrideHashFn(credit), 0, 'value', 'talent']);

  if (talent) {
    const {
      talent: omitted,
      ...role
    } = credit;
    return { ...role,
      talent
    };
  }

  return credit;
};

const getSortedRoles = editorLists => {
  return [// parent
  ...(editorLists.season ? editorLists.season.sortedLocalizedCrew : []).filter(preventHidden(editorLists.season || {})), ...(editorLists.season ? editorLists.season.sortedLocalizedCast : []).filter(preventHidden(editorLists.season || {})).map(swapOverrideTalent(editorLists.season || {})), ...(editorLists.season ? editorLists.season.sortedLocalizedVoices : []).filter(preventHidden(editorLists.season || {})), // title
  ...editorLists.title.sortedLocalizedCrew, ...editorLists.title.sortedLocalizedCast, ...editorLists.title.sortedLocalizedVoices];
};

exports.getSortedRoles = getSortedRoles;

const organizeTitleCredits = (metadata, lang, child) => {
  const localizedDocs = metadata && metadata.localized || [];
  const indexLocalizedByLang = (0, _deepdown.indexByKey)(localizedDocs, ['language']);
  const originalLanguage = metadata.original && metadata.original.language;

  if (!originalLanguage) {
    return {};
  }

  const fallbackHash = c => (0, _deepdown.drillDown)(c, ['character', 'id']);

  const originalCredits = (0, _deepdown.drillDown)(indexLocalizedByLang, [originalLanguage, 0, 'credits']) || [];
  const indexFallbacks = (0, _deepdown.indexByKey)(originalCredits, ['character', 'id']);
  const childLocalized = child && child.metadata && child.metadata.localized && child.metadata.localized.find(loc => loc.language === lang);
  const override = childLocalized && childLocalized.overrides && childLocalized.overrides.find(ov => ov.mpm === metadata.mpm);
  const overrideCredits = override && override.credits || [];
  const indexOverrideMatches = indexByHash(overrideCredits, defaultOverrideHash(defaultOverrideHashOverrideElements));
  const overrideHashFn = defaultOverrideHash(defaultOverrideHashCreditElements);
  const localizedCredits = (0, _deepdown.drillDown)(indexLocalizedByLang, [lang, '0', 'credits']) || [];
  const localizedCreditsWithCharacter = localizedCredits.filter(hasCharacter);
  const indexLocalizedByOriginalCharacter = (0, _deepdown.indexByKey)(localizedCreditsWithCharacter, ['character', 'id']);
  const localizedCreditsGroupedByRole = (0, _deepdown.indexByKey)(localizedCredits || [], ['role']);
  const sortedLocalizedPerformances = Object.keys(localizedCreditsGroupedByRole).filter(role => _roleTypes.castRoles.includes(role)).map(role => localizedCreditsGroupedByRole[role]).reduce((accum, list) => [...accum, ...list], []);
  const sortedLocalizedCast = sortedLocalizedPerformances.filter(credit => hasCharacter(credit)).sort(billingSort({
    indexFallbacks,
    fallbackHash,
    indexOverrides: indexOverrideMatches,
    overrideHash: overrideHashFn
  }));
  const sortedLocalizedVoices = sortedLocalizedPerformances.filter(credit => !hasCharacter(credit)).sort(billingSort({
    indexOverrides: indexOverrideMatches,
    overrideHash: overrideHashFn
  })); // to make a spot in the editor for each character

  const sortedOriginalCharacters = [...originalCredits].filter(hasCharacter).sort(billingSort());
  const sortedLocalizedCrew = Object.keys(localizedCreditsGroupedByRole).filter(role => !_roleTypes.castRoles.includes(role)).map(role => localizedCreditsGroupedByRole[role]).reduce((accum, list) => [...accum, ...list], []).sort(billingSort({
    indexOverrides: indexOverrideMatches,
    overrideHash: overrideHashFn
  }));
  return {
    metadata,
    indexLocalizedByOriginalCharacter,
    indexOverrideMatches,
    overrideHashFn,
    sortedOriginalCharacters,
    sortedLocalizedCrew,
    sortedLocalizedCast,
    sortedLocalizedVoices
  };
};

const organizeCreditsForEditor = (mpm, titles, lang) => {
  if (!titles[mpm]) {
    return {};
  }

  const currentTitle = titles[mpm];
  const seasonTitle = currentTitle && currentTitle.mpmProductNumber && titles[currentTitle.mpmProductNumber]; // const seriesTitle = currentTitle && (currentTitle.mpmFamilyNumber ? titles[currentTitle.mpmFamilyNumber] : (seasonTitle && seasonTitle.mpmFamilyNumber && titles[seasonTitle.mpmFamilyNumber]))

  const title = organizeTitleCredits(currentTitle, lang);
  const season = seasonTitle && organizeTitleCredits(seasonTitle, lang, title);
  return {
    title,
    season
  };
}; // --- utility --- //
// `matchCredit` should be used like: `array.find(matchCredit(criteria))`.
//
// it attempts to find a match with the least criteria, similar to how
// mongo would return the first match it finds within an array.
//
// however, if `restrict` is enabled, additional criteria will be added
// to prvent character credits from being identified as non-character credits,
// mimicking the extra DML required to specify non-character credits only.


exports.organizeCreditsForEditor = organizeCreditsForEditor;

const matchCredit = (toMatch, restrict = true) => candidate => {
  return candidate.role === toMatch.role && candidate.talent.id === toMatch.talent.id // && (candidate.talent.aka === toMatch.talent.aka)
  && (!toMatch.character ? true : candidate.character && candidate.character.id === toMatch.character.id) // && (!toMatch.character ? true : (candidate.character.aka === toMatch.character.aka))
  && (!restrict ? true : !candidate.character ? true : toMatch.character && candidate.character.id === toMatch.character.id);
};

exports.matchCredit = matchCredit;