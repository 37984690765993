"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "delivery", {
  enumerable: true,
  get: function () {
    return _deliveryPlatforms.default;
  }
});
Object.defineProperty(exports, "schema", {
  enumerable: true,
  get: function () {
    return _schema.default;
  }
});
Object.defineProperty(exports, "svg", {
  enumerable: true,
  get: function () {
    return _svg.default;
  }
});
Object.defineProperty(exports, "templates", {
  enumerable: true,
  get: function () {
    return _templateSettings.default;
  }
});
Object.defineProperty(exports, "vocabulary", {
  enumerable: true,
  get: function () {
    return _vocabulary.default;
  }
});
Object.defineProperty(exports, "interpolate", {
  enumerable: true,
  get: function () {
    return _interpolate.default;
  }
});
exports.roles = exports.rendering = exports.models = exports.layout = exports.formats = exports.bulk = void 0;

var _deliveryPlatforms = _interopRequireDefault(require("./delivery-platforms"));

var bulk = _interopRequireWildcard(require("./bulk"));

exports.bulk = bulk;

var formats = _interopRequireWildcard(require("./format-settings"));

exports.formats = formats;

var layout = _interopRequireWildcard(require("./layout"));

exports.layout = layout;

var models = _interopRequireWildcard(require("./models"));

exports.models = models;

var rendering = _interopRequireWildcard(require("./rendering"));

exports.rendering = rendering;

var _schema = _interopRequireDefault(require("./schema.json"));

var _svg = _interopRequireDefault(require("./svg"));

var roles = _interopRequireWildcard(require("./role-types"));

exports.roles = roles;

var _templateSettings = _interopRequireDefault(require("./template-settings"));

var _vocabulary = _interopRequireDefault(require("./vocabulary"));

var _interpolate = _interopRequireDefault(require("./interpolate"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }