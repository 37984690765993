import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import WMLoginButton from './WMLoginButton'

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '100%',
  },
  item: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
}))

const SplashLogin = () => {
  const classes = useStyles()
  return (
    <Grid container direction="column" wrap="nowrap" justify="center" alignItems="center" className={classes.fullHeight}>
      <Typography variant="h3" component="h1" className={classes.item}>WM Localization</Typography>
      <Typography variant="body1" className={classes.item}>Record tracking for audio dub talent, dubcard generation, and more.</Typography>
      <Grid item className={classes.item}> <WMLoginButton id="login" /> </Grid>
    </Grid>
  )
}

export default SplashLogin
