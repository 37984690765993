"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _roleTypes = require("./role-types");

const templatesByName = {
  Studio: {
    heading: 'Localized Version',
    subHeading: '',
    // forceNewPage: true,
    roleFilter: {
      excludes: _roleTypes.castRoles
    }
  },
  Characters: {
    heading: 'Localized Version',
    subHeading: 'With the Voices of',
    // forceNewPage: true,
    roleFilter: {
      includes: _roleTypes.castRoles,
      checkCharacter: {
        value: true
      }
    }
  },
  Unnamed: {
    heading: 'Localized Version',
    subHeading: 'Additional Voices',
    // forceNewPage: true,
    hideRole: true,
    roleFilter: {
      includes: _roleTypes.castRoles,
      checkCharacter: {
        value: false
      }
    }
  }
};
var _default = templatesByName;
exports.default = _default;