"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// inspired by:
// https://stackoverflow.com/a/41972664/1740008
const reg = /\$\{[^}]+\}/g;

function interpolate(template, map, fallback) {
  return template.replace(reg, match => match.slice(2, -1).trim().split(".").reduce((searchObject, key) => searchObject.hasOwnProperty(key) ? searchObject[key] : fallback || match, map));
}

var _default = interpolate;
exports.default = _default;