"use strict";

const {
  LayoutSettings,
  defaultLayout,
  defaultFont
} = require('./layout');

exports.creditsToSvg = (input, layout, layoutOverride) => {
  const settings = { ...(layout ? LayoutSettings[layout] : LayoutSettings[defaultLayout]),
    ...(layoutOverride || {})
  };

  if (!settings.font) {
    settings.font = defaultFont;
  }

  const data = [`<svg id="preview" version="1.1" baseProfile="full" viewBox="0 0 ${settings.width} ${settings.height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <style type="text/css"> text { font-family: "${settings.font}", sans-serif } </style> <rect width="100%" height="100%" fill="black" /> <text x="960" y="300" font-size="20" text-anchor="middle" fill="white">Version Française</text> <text x="930" y="370" font-size="20" text-anchor="end" fill="white">RACHEL</text> <text x="990" y="370" font-size="20" text-anchor="start" fill="white">FRENCH JENNIFER ANISTON</text> <text x="930" y="400" font-size="20" text-anchor="end" fill="white">LA MONICA 2</text> <text x="990" y="400" font-size="20" text-anchor="start" fill="white">FRENCH COURTNEY COX 2</text> <text x="930" y="430" font-size="20" text-anchor="end" fill="white">LE CHANDLER</text> <text x="990" y="430" font-size="20" text-anchor="start" fill="white">FRENCH MATTHEW PERRY</text> </svg>`];
  return {
    data,
    settings
  };
};